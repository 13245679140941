import { TFunction } from '@wix/yoshi-flow-editor';
import { Sender } from '@wix/ambassador-innovation-widget-v1-message/types';

import { ProductVaseImage1, ProductVaseImage2 } from './dummyImagesBase64';
import { APIMessage } from './messagesSlice';

let counter = 0;

export const getDummyVisitorMessages = (t: TFunction): APIMessage[] => [
  {
    id: '1',
    createdDate: new Date(Date.now() + counter++),
    sender: Sender.SITE_VISITOR,
    body: {
      mainText: t('app.demoContent.question1Text'),
    },
  },
];

export const getDummyAssistantMessages = (t: TFunction): APIMessage[] => [
  {
    id: '4',
    createdDate: new Date(Date.now() + counter++),
    sender: Sender.ASSISTANT,
    body: {
      mainText: t('app.demoContent.answer2Text'),
      additionalData: [
        {
          id: 'cta',
          type: 'site_pages',
          title: t('app.demoContent.answer2Cta'),
          url: '/contanct-us',
        },
      ],
    },
  },
];

export const getDummyCardMessages = (t: TFunction): APIMessage[] => [
  {
    id: '2',
    createdDate: new Date(Date.now() + counter++),
    sender: Sender.ASSISTANT,
    body: {
      mainText: t('app.demoContent.answer1Text'),
      additionalData: [
        {
          id: 'p1',
          type: 'products',
          title: t('app.demoContent.answer1Product1Title'),
          img: ProductVaseImage1,
          url: '/product/1',
          price: '$129.90',
        },
        {
          id: 'p2',
          type: 'products',
          title: t('app.demoContent.answer1Product1Title'),
          img: ProductVaseImage2,
          url: '/product/2',
          price: '$89.90',
        },
      ],
    },
  },
];

export const getDummyMessages = (t: TFunction): APIMessage[] => [
  {
    id: '1',
    createdDate: new Date(Date.now() + counter++),
    sender: Sender.SITE_VISITOR,
    body: {
      mainText: t('app.demoContent.question1Text'),
    },
  },
  {
    id: '2',
    createdDate: new Date(Date.now() + counter++),
    sender: Sender.ASSISTANT,
    body: {
      mainText: t('app.demoContent.answer1Text'),
      additionalData: [
        {
          id: 'p1',
          type: 'products',
          title: t('app.demoContent.answer1Product1Title'),
          img: ProductVaseImage1,
          url: '/product/1',
          price: '$129.90',
        },
        {
          id: 'p2',
          type: 'products',
          title: t('app.demoContent.answer1Product1Title'),
          img: ProductVaseImage2,
          url: '/product/2',
          price: '$89.90',
        },
      ],
    },
  },
  {
    id: '3',
    createdDate: new Date(Date.now() + counter++),
    sender: Sender.SITE_VISITOR,
    body: {
      mainText: t('app.demoContent.question2Text'),
    },
  },
  {
    id: '4',
    createdDate: new Date(Date.now() + counter++),
    sender: Sender.ASSISTANT,
    body: {
      mainText: t('app.demoContent.answer2Text'),
      additionalData: [
        {
          id: 'cta',
          type: 'site_pages',
          title: t('app.demoContent.answer2Cta'),
          url: '/contanct-us',
        },
      ],
    },
  },
];
