import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const enum VisualLayoutState {
  Minimized = 'minimized',
  Visible = 'visible',
}
export type LayoutState = {
  visual: VisualLayoutState;
  wasOpened: boolean;
};

const initialState: LayoutState = {
  visual: VisualLayoutState.Minimized,
  wasOpened: false,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setVisual: (state, { payload }: PayloadAction<VisualLayoutState>) => {
      if (payload === 'visible') {
        state.wasOpened = true;
      }
      state.visual = payload;
    },
    replace: (_, { payload }: PayloadAction<LayoutState>) => {
      return payload;
    },
  },
});

export const { reducer: layoutReducer } = layoutSlice;
