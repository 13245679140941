import React from 'react';

import { Provider as ReduxProvider } from 'react-redux';

import { ISiteApis, useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';

import { RootState, useGetStore } from '@/store';
import { isStudioSite } from '@/utils';
import { VisualLayoutState } from '@/features';

import { OnHandlerEvent, OnHandlerReset } from 'AiAssistantWidget/types';

import {
  EditorSettingsEventsProvider,
  RootPropsProvider,
  SiteNavigation,
  SiteNavigationProvider,
} from '../../contexts';
import { ChatWrapper } from '../ChatWrapper';
import { MobileActionBarButton } from '../MobileActionBarButton';

export type ControllerProps = {
  onHandlerEvent: OnHandlerEvent;
  onHandlerReset: OnHandlerReset;
  navigation: SiteNavigation;
  initialLayoutOpen: boolean;
  instanceToken: string;
  page: ISiteApis['currentPage'];
  avatar?: string;
};

export const ControllerValidation: React.FC<WidgetProps<ControllerProps>> = ({
  onHandlerEvent,
  onHandlerReset,
  navigation,
  initialLayoutOpen,
  instanceToken,
  avatar,
  page,
  host,
}) => {
  const { isEditor } = useEnvironment();
  const initialState: Partial<RootState> | undefined =
    initialLayoutOpen && isEditor
      ? {
          layout: {
            visual: VisualLayoutState.Visible,
            wasOpened: true,
          },
        }
      : undefined;
  const store = useGetStore(instanceToken!, initialState);
  return (
    <div>
      <ReduxProvider store={store}>
        <RootPropsProvider p={{ page, instanceToken, host, avatar }}>
          <EditorSettingsEventsProvider
            onHandlerEvent={onHandlerEvent}
            onHandlerReset={onHandlerReset}
          >
            <SiteNavigationProvider navigation={navigation}>
              <ChatWrapper />
              {!isStudioSite() && <MobileActionBarButton host={host} />}
            </SiteNavigationProvider>
          </EditorSettingsEventsProvider>
        </RootPropsProvider>
      </ReduxProvider>
    </div>
  );
};
