import * as ConversationApi from '@wix/ambassador-innovation-widget-v1-conversation/http';

import { createAppAsyncThunk } from '../createAppAsyncThunk';

import { toConversationsQuota } from './model';
import { shouldLoad as selectShouldLoad } from './conversationsQuotaSelectors';

export const getConversationsQuota = createAppAsyncThunk(
  'chat/getConversationsQuota',
  async (_, { extra: { httpClient: client } }) => {
    const {
      data: { quota, current },
    } = await client.request(ConversationApi.getConversationsUsage({}));

    return toConversationsQuota({
      quota,
      current,
    });
  },
);

export const getConversationsQuotaThrottled = createAppAsyncThunk(
  'chat/getConversationsQuota/throttled',
  (_, { dispatch, getState }) => {
    const shouldLoad = selectShouldLoad(getState());

    if (shouldLoad) {
      return dispatch(getConversationsQuota());
    }
  },
);
