import {
  APIGetWidgetSettingsResponse,
  GetWidgetSettingsResponse,
} from './settingsSlice';

export const formatSettingsResonse = (
  response: APIGetWidgetSettingsResponse,
): GetWidgetSettingsResponse => ({
  ...response,
  widgetSettings: {
    ...response.widgetSettings,
    createdDate: response.widgetSettings?.createdDate?.getTime() ?? 0,
    updatedDate: response.widgetSettings?.updatedDate?.getTime() ?? 0,
  },
});
